/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { footerStyles } from './styles';
import ThemeContext from '../../utils/theme-context';

export default function FooterLinks({ data }) {
  const partnerTheme = useContext(ThemeContext);
  const classes = footerStyles(partnerTheme);
  const [privacyUrl, setPrivacyUrl] = useState('');
  const [truste, setTruste] = useState(null);

  useEffect(() => {
    const getUrl = async () => {
      const url = await JSON.parse(localStorage.getItem('privacyUrl'));
      const encodedUrl = encodeURIComponent(url);
      setPrivacyUrl(encodedUrl);
    };
    getUrl();
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://consent.trustarc.com/notice?domain=realogyleadsgroup.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&privacypolicylink=${privacyUrl}`;
    script.async = true;
    script.onload = () => {
      setTruste(window.truste.eu);
    };
    const cookieAttr = document.getElementById("cookie-consent-btn");
    if(cookieAttr){
	    cookieAttr.removeAttribute('aria-current')
    }
    document.body.appendChild(script);
  }, []);

  const handleCookiePreferencesClick = () => {
    if (truste) {
      truste.clickListener();
    }
  };

  return (
    <>
      <Container id="footer-links-space" maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            className={classes.footerLinks}
          >
            <nav aria-label="footer-links">
              <ul className='menu-item'>
                {data.links.map((link) => {
                  const isNewTab = link.link.openInANewTab ? 'blank' : '';
                  return (
                    <li id={`footer-link-${link.label}`} key={link.link.id}>
                      <Link aria-label={link.label} to={!link.link.openInANewTab ? `/${link.link.referenceToPage?.pageName}` : `${link.link.url}`} target={isNewTab} className={classes.links}>{link.label}</Link>
                    </li>);
                })}
                <li id="footer-link-cookie-consent">
                  <Link id="cookie-consent-btn" to="/" className={classes.cookieLink} onClick={handleCookiePreferencesClick} role="button">Cookie Preferences</Link>
                </li>
              </ul>
            </nav>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
