/* eslint-disable max-len */
import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import ThemeContext from '../../utils/theme-context';
import { customeTheme } from '../../styles';
import '../../styles.scss';

const useStyles = makeStyles(() => ({
  skipLink: (styles) => ({
    position: 'absolute',
    top: '-40px',
    left: '0',
    padding: '6px',
    backgroundColor: '#000000 !important',
    color: '#FFF !important',
    border: '2px solid transparent !important',
    fontSize: '1.4em !important',
    borderRadius: '0 !important',
    borderBottomRightRadius: '8px !important',
    '&:focus': {
      left: '0',
      top: '0',
      transition: 'top 1s ease-out',
      zIndex: '9999'
    }
  }),
  hideHome:{
    visibility: 'hidden'
  },
  main: {
    '& a': {
      borderBottom: '1px solid'
    }
  }
}));

export default function Layout({
  navigation, footer, children, styles, metadata, contactFormDetails, logout, ast, siteType, searchEnabled, astEnabled, leadFormInfo
}) {
  const classes = useStyles(styles);
  const [toggle, setToggle] = useState(false);
  const [partnerTheme] = useState(styles);
  return (
    <>
      {styles.typography.googleFontHeading && styles.typography.googleFontBody
        && (
          <Helmet>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
            <link href={`https://fonts.googleapis.com/css2?family=${styles.typography?.googleFontHeading}&family=${styles.typography?.googleFontBody}&display=swap`} rel="stylesheet" />
          </Helmet>
        )}
      <a href="#main" className={toggle ? classes.hideHome :classes.skipLink}>Skip to main content</a>
      <ThemeContext.Provider value={partnerTheme}>
        <ThemeProvider theme={customeTheme(partnerTheme)}>
          <Header navigation={navigation} buttonStyle={styles.buttonStyle.buttonBackgroundColor} toggle={toggle} setToggle={setToggle} siteMetadata={metadata} contactForm={contactFormDetails} logout={logout} astData={ast} siteType={siteType} isSearchEnabled={searchEnabled} isAst={astEnabled} leadFormDetails={leadFormInfo} />
          <main id="main" className={toggle ? classes.hideHome : classes.main} tabIndex="-1">
            <Container maxWidth="xl" style={{ padding: '0' }}>
              {children}
            </Container>
            <div id="consent_blackbar" className="cookie-consent"> </div>
          </main>
          <Footer footer={footer} toggle={toggle} />
        </ThemeProvider>
      </ThemeContext.Provider>
    </>
  );
}
