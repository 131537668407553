/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

export const richTextStyles = makeStyles((theme) => ({
  richTextContainer: () => ({
    background: '#f0f0f0 '
  }),
  richTextPara: (data) => ({
    '& h1': {
      fontFamily: `${data?.typography?.headingFontFamily}` || 'Georgia, Arial, Helvetica, sans-serif',
      fontSize: `${data?.typography?.h1}em` || '2.5em',
      fontWeight: '600',
      lineHeight: '1.3'
    },
    '& h2': {
      fontFamily: `${data?.typography?.headingFontFamily}` || 'Georgia, Arial, Helvetica, sans-serif',
      fontSize: `${data?.typography?.h2}em` || '2.5em',
      fontWeight: '400',
      lineHeight: '1.3'
    },
    '& h3': {
      fontFamily: `${data?.typography?.headingFontFamily}` || 'Georgia, Arial, Helvetica, sans-serif',
      fontSize: `${data?.typography?.h3}em` || '1.5em',
      fontWeight: '700',
      lineHeight: '1.5'
    },
    '& h4': {
      fontFamily: `${data?.typography?.headingFontFamily}` || 'Georgia, Arial, Helvetica, sans-serif',
      fontSize: `${data?.typography?.h4}em` || '1.5em',
      fontWeight: '400',
      lineHeight: '1.5'
    },
    '& h5': {
      fontFamily: `${data?.typography?.headingFontFamily}` || 'Georgia, Arial, Helvetica, sans-serif',
      fontSize: `${data?.typography?.h5}em` || '1.15em',
      fontWeight: '400',
      lineHeight: '1.5'
    },
    '& h6': {
      fontFamily: `${data?.typography?.headingFontFamily}` || 'Georgia, Arial, Helvetica, sans-serif',
      fontSize: `${data?.typography?.h6}em` || '1em',
      fontWeight: '700',
      lineHeight: '1.5'
    },
    '& p': {
      fontWeight: '400',
      lineHeight: `${data?.typography?.bodyLineHeight || '1.5'}em`,
      paddingBottom: '5px',
      margin: 0
    }
  })
}));

export const secondaryTextStyles = makeStyles(() => ({
  achorLink: (data) => ({
    '& a': {
      color: data?.anchorLinkColor || '#464749',
      borderBottom: data?.hasAnchorLinkWithDottedUnderline && '1px dotted'
    }
  })
}));
