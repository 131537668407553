import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  root: (data) => ({
    width: `${data?.ifItIsHalfWidthHeroBannerImage ? '100%' : 'auto'}`,
    maxWidth: '100%',
    maxHeight: data?.maxHeight || '100%'
  })
}));

export default function ImageOnly({ data }) {
  const classes = useStyles(data);
  return (
    <Grid
      container
      id={`image-logo-${data?.image?.description}`}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <img src={data?.image?.file?.url} alt={data?.image?.description} className={classes.root} />
    </Grid>
  );
}
