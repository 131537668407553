/* eslint-disable react/no-danger */
import React from 'react';
import '../../styles.scss';
import { Container } from '@material-ui/core';

export default function EmbeddedVideo({ data }) {
  return (
    <Container id="embedded-video">
      <div className="iframe-container">
        <div dangerouslySetInnerHTML={{ __html: data.embeddedUrl.embeddedUrl }} />
      </div>
    </Container>
  );
}
