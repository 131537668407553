/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core';

const alignMap = {
  Left: 'flex-start',
  Center: 'center',
  End: 'flex-end'
};
export const myStyles = makeStyles((theme) => ({
  root: (container) => ({
    maxWidth: '100%',
    flexGrow: 1,
    backgroundColor: container?.backgroundColor || 'transparent',
    backgroundImage: container?.backgroundImage ? `url(${container.backgroundImage.file.url})` : null,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    zIndex: 2,
    '&::before': {
      content: '""',
      backgroundColor: container?.backgroundImageOverlayColor || 'transparent',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      zIndex: -1,
      opacity: container?.backgroundImageOverlayOpacity
        ? container.backgroundImageOverlayOpacity / 100 : 1
    },
    '& #superText': {
      fontFamily: `${theme.typography.h1.fontFamily}`,
      fontSize: '16px !important',
      fontWeight: '400'
    }
  }),
  calcTile: (data) => ({
    color: data.calcRewardTextColor ? data.calcRewardTextColor : '#000000',
    paddingLeft: data.rewardCalcViewType === 'Horizontal' ? '10px' : '0px',
    textAlign: 'center'
  }),
  horizontalGrid: (data) => ({
    flexDirection: data.rewardCalcViewType === 'Horizontal' ? 'row' : 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }),
  paper: () => ({
    color: 'white',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderRadius: '0px !important',
    [theme.breakpoints.up('xs')]: {
      padding: '0px',
      textAlign: 'left'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0',
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      padding: '24px 105px 24px 0px',
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '24px 105px 24px 0px',
      textAlign: 'left'
    }
  }),
  Overlay: () => ({
    backgroundColor: 'rgba(2,78,67,0.8) !important',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0'
  }),
  gridWrapper: () => ({
    position: 'relative',
    margin: 'auto',
    verticalAlign: 'middle',
    minHeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  }),
  rewardLeftText: () => ({
    paddingRight: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  }),
  newContainer: () => ({
    position: 'relative',
    padding: '80px 140px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 25px'
    }
  }),

  rewardCalc: () => ({
    backgroundColor: '#ffffff',
    color: '#000',
    textAlign: 'center',
    padding: '35px 0'
  }),
  rewardCalcInput: (data) => ({
    width: data.rewardCalcViewType === 'Horizontal' ? '40%' : '85%',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: data.rewardCalcViewType === 'Horizontal' ? '92%' : '85%'
    }
  }),
  rewardCashText: (data) => ({
    color: '#464749 !important',
    marginBottom: data.rewardCalcViewType === 'Horizontal' ? '0px' : '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: data.rewardCalcViewType === 'Horizontal' ? '15px' : '20px'
    }
  }),
  gridColumn: () => ({
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }),
  titleHeading: (data) => ({
    color: data?.cashBackTextColor ? data.cashBackTextColor : '#000000',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      width: '100%'
    }
  }),
  p: (data) => ({
    color: data?.cashBackTextColor ? data.cashBackTextColor : 'inherit',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    }
  }),
  underline: (data) => ({
    display: 'flex',
    width: '100%',
    justifyContent: alignMap[data.alignText] || 'flex-start',
    marginBottom: '20px'
  }),
  hr: () => ({
    width: '80px',
    height: '8px',
    border: 'none',
    borderRadius: '0',
    [theme.breakpoints.up('xs')]: {
      margin: '0 auto'
    },
    [theme.breakpoints.up('md')]: {
      margin: '0'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0'
    }
  }),
  cashbackFormSignupBtn: () => ({
    borderBottom: 'none !important'
  }),
  signupButton: () => ({
    borderBottom: 'none !important'
  }),
  sronly: ()=> ({
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: '0'
  }),
}));

export const rewardStyles = makeStyles(() => ({
  rewardCalcInputField: (data) => ({
    '& input[type="number" i]': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none'
      }
    },
    '& .MuiFilledInput-root': {
      borderRadius: '0px !important'
    },
    '& .MuiFilledInput-root::before': {
      borderBottom: '1px solid #f0f0f0 !important'
    },
    '& .MuiFormLabel-root': {
      fontFamily: `${data.typography.body2}` || 'Georgia, Helvetica Neue, Arial, sans-serif'
    }
  }),
  rewardH2: (data) => ({
    color: `${data?.buttonStyle?.buttonBackgroundColor || '#464749'} !important`,
    fontWeight: '600'
  })
}));
