/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, { useState, useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Container, FormControl, TextField, Button
} from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { myStyles, rewardStyles } from './styles';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';
import superScriptFormat from '../../utils/superscript-check';
const colored ={
  color:'#62696A',
};
const InputBackgroundColor = {
  backgroundColor : '#E8E8E8',
  '::placeholder':{
    color:'blue'
  }
}
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function CashBackForm({ data }) {
  const partnerTheme = useContext(ThemeContext);
  const [ast, setAst] = useState(false);
  const [flowOrder, setFlowOrder] = useState('');
  const cashbackTable = data.cashBackTable;
  const [cashback, setCashback] = useState(0);
  const [purchasePrice, setPurchasePrice] = useState('');
  const classes = myStyles(data);
  const rewardClass = rewardStyles(partnerTheme);
  const globalthemClasses = themeStyles(partnerTheme);

  useEffect(() => {
    setAst(JSON.parse(localStorage.getItem('ast')));
    setFlowOrder(JSON.parse(localStorage.getItem('flowOrder')));
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      getCashback(purchasePrice);
    }, 500);

    return () => clearTimeout(timer);
  }, [purchasePrice]);

  const variantVal = partnerTheme?.buttonStyle?.buttonType === 'contained'
    || partnerTheme?.buttonStyle?.buttonType === 'outlined'
    || partnerTheme?.buttonStyle?.buttonType === 'text'
    ? partnerTheme?.buttonStyle?.buttonType
    : 'contained';
  let btnClassName;
  if (variantVal === 'contained') {
    btnClassName = globalthemClasses.containedBtn;
  } else if (variantVal === 'outlined') {
    btnClassName = globalthemClasses.outlinedBtn;
  } else {
    btnClassName = globalthemClasses.textBtn;
  }

  let showModal;
  if (ast) {
    showModal = '/ast';
  } else {
    switch (flowOrder) {
      case 'Selling Only':
        showModal = '/customer-info';
        break;
      case 'Buying Only':
        showModal = '/customer-info';
        break;
      case 'Buying, Selling':
        showModal = '/service-selection';
        break;
      case 'Selling, Buying':
        showModal = '/service-selection';
        break;
      default:
        showModal = '/';
    }
  }

  const navigateToPage = (path) => {
    navigate(path);
  };

  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  const addSRMessage = (text) => {
    console.log('printing the reward update  ', text);
    const tag = document.createElement('p');
    const textEle = document.createTextNode(text);
    tag.appendChild(textEle);
    const element = document.getElementById('sr-message');
    element.appendChild(tag);
    setTimeout(() => {
      removeSRMessage();
    }, 500);
  };
  const removeSRMessage = () => {
    const element = document.getElementById('sr-message');
    element.innerHTML = '';
  };

  const getCashback = (e) => {
    if (!e) {
      setCashback(0);
      const obj = document.getElementById('cashback');
      animateValue(obj, 0, 0, 1000);
    }
    // eslint-disable-next-line array-callback-return
    if (!data.enableCashBackPercentage) {
      cashbackTable.filter((x) => {
        if (parseInt(e, 10) >= x.min && parseInt(e, 10) <= x.max) {
          setCashback(x.cashback);
          if (x.cashback) {
            const msg = 'Reward Updating $'.concat(String(x.cashback));
            addSRMessage(msg);
          }
          const obj = document.getElementById('cashback');
          animateValue(obj, 0, x.cashback, 1000);
        }
        return x.cashback;
      });
    } else {
      const cashbackPercent = Math.ceil(
        (data.cashBackPercentage[0].cashback / 100) * e
      );
      setCashback(cashbackPercent);
      const obj = document.getElementById('cashback');
      animateValue(obj, 0, cashbackPercent, 1000);
    }
  };

  return (
    <div id="cmp-cashback-reward-calc" className={classes.root}>
      <Container className={classes.newContainer}>
        <Grid container spacing={2}>
          <Grid
            container
            className={classes.rewardLeftText}
            alignContent="center"
            justifyContent="space-between"
            xs={12}
            md={6}
          >
            <Typography
              id="cashback-reward-h2-heading"
              variant="h2"
              className={classes.titleHeading}
            >
              {superScriptFormat(data.title)}
            </Typography>
            {data.isUnderline && (
              <div className={classes.underline}>
                <hr
                  className={clsx(
                    globalthemClasses.progressBarColor,
                    classes.hr
                  )}
                />
              </div>
            )}
            {data?.subText && (
              <Typography
                id="cashback-reward-paragraph"
                variant="body2"
                className={classes.p}
              >
                {data.subText.subText}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            xs={12}
            md={6}
            spacing={1}
            className={classes.gridWrapper}
          >
            <Grid item xs>
              <Paper
                role="region"
                aria-label="calculator"
                className={clsx(
                  classes.paper,
                  classes.rewardCalc,
                  globalthemClasses.cashbackTop
                )}
              >
                <Typography
                  id="reward-calculator-h3-heading"
                  variant="h3"
                  component="h3"
                  className={classes.calcTile}
                >
                  {data.calculatorTitle}
                </Typography>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  className={classes.horizontalGrid}
                >
                  <FormControl
                    fullWidth
                    noValidate
                    autoComplete="off"
                    className={classes.rewardCalcInput}
                  >
                    <div className={classes.sronly} id="ada-only-text">
                      Enter in the purchase or sales price of your house
                    </div>

                    <TextField
                      id="filled-basic"
                      label="Purchase or Sale Price"
                      aria-live="off"
                      aria-busy="true"
                      placeholder="Enter Purchase or Sale Price"
                      variant="filled"
                      onKeyDown={(event) => ['e', 'E', '+', '-'].includes(event.key)
                        && event.preventDefault()}
                      className={rewardClass.rewardCalcInputField}
                      onChange={(event) => setPurchasePrice(event.target.value)}
                      helperText
                      InputLabelProps={{style:colored}}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        'aria-describedby': 'ada-only-text',
                        style:InputBackgroundColor
                      }}
                    />
                  </FormControl>
                  <div
                    role="alert"
                    aria-live="polite"
                    aria-atomic="true"
                    id="sr-message"
                    style={{ opacity: 0, height: 0, width: 0 }}
                  />
                  <Box>
                    <Typography
                      id="reward-calculator-h2-reward"
                      display="inline"
                      variant="h2"
                      className={rewardClass.rewardH2}
                    >
                      =
                      {data.showSign && ' $'}
                    </Typography>
                    <Typography
                      display="inline"
                      variant="h2"
                      className={rewardClass.rewardH2}
                      id="cashback"
                      aria-describedby="filled-basic"
                    >
                      {cashback}
                    </Typography>
                  </Box>

                  <Typography
                    id="cashback-text"
                    variant="body2"
                    className={classes.rewardCashText}
                  >
                    {data.rewardDisclaimer}
                  </Typography>
                  <Button
                    component={Link}
                    id="cashback-sign-up-btn"
                    variant={variantVal}
                    color="primary"
                    disableRipple
                    className={clsx(`${btnClassName}`, classes.signupButton)}
                    onClick={() => navigateToPage(showModal)}
                    size="large"
                    to={showModal}
                    role="link"
                  >
                    {data.calculatorLink.label}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
