/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundImage: `url(${props.bgImg[0].bannerImg1})`,
    display: 'flex',
    backgroundPosition: 'center',
    color: '#fff',
    flexDirection: 'column',
    marginTop: '20px',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none'
    },
    '& #superText': {
      fontFamily: `${theme.typography.h1.fontFamily}`,
      fontSize: '16px !important',
      fontWeight: '400'
    }
  }),
  section: (props) => ({
    background: 'linear-gradient(90deg, rgba(52, 52, 52, 0.7) 43%, rgba(255, 255, 255, 0) 66%)',
    backgroundImage: 'linear-gradient(to right, rgba(52, 52, 52, 0.7), rgba(255, 255, 255, 0))',
    width: '100%',
    padding: '0px 80px',
    minHeight: '68vh',
    display: 'flex',
    justifyContent: `${props.bgImg[0].justifyC}`,
    alignItems: `${props.bgImg[0].alignI}`,
    textAlign: `${props.bgImg[0].textAlign}`,
    [theme.breakpoints.down('sm')]: {
      background: 'none',
      color: '#000',
      padding: '30px 30px 50px',
      minHeight: 'auto'
    }
  }),
  banner: () => ({
    width: '100%',
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }),
  gridWidth: () => ({
    width: '36%',
    flex: 'none',
    padding: '24px 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100%',
      padding: '20px 0px'
    }
  }),
  bannerImage: () => ({
    width: '100%',
    backgroundPosition: 'cover',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }),
  columnGrid: () => ({
    display: 'block',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      // alignItems: 'flex-center'
    }
  }),
  bannerBtn: () => ({
    margin: '20px 0',
    borderBottom: 'none !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important'
    }
  })
}));
