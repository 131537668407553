/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React, { useContext } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Container, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { richTextStyles, secondaryTextStyles } from './styles';
import ThemeContext from '../../utils/theme-context';

export const RichText = ({ data }) => {
  const partnerTheme = useContext(ThemeContext);
  const classes = richTextStyles(partnerTheme);
  const secondaryLinkStyles = secondaryTextStyles(data);
  return (
    <Container id={data?.anchor_id}>
      <Typography variant="body2" className={clsx(classes.richTextPara, secondaryLinkStyles.achorLink)}>{documentToReactComponents(JSON.parse(data?.richText?.raw))}</Typography>
    </Container>
  );
};
